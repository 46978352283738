const ZH = {
    webName: '在线客服',
    Common: {
        t1: "请勿频繁操作",
        t2: "服务异常"
    },
    Home: {
        t1: "联系客服",
        t2: "点卡充值在线平台",
        t3: "发送(s)",
        t4: "请输入您的问题",
        t5: "  为您服务",
        t6: "用户",
        t7: "发送内容不能为空",
        t8: "请选择客服",
        t9: "选择"
    },
    customerServiceLogin: {
        t1: "客服登录",
        t2: "请输入账号ID",
        t3: "请输入密码",
        t4: "清空",
        t5: "登录",
    },
    customerService: {
        t1: "昵称：",
        t2: "最大同时可接待：",
        t3: "接待次数：",
        t4: "在线",
        t5: "离线",
        t6: "您有一位新的用户访问",
        t7: "在线会话",
        t8: "踢出会话",
        t9: "离线会话",
        t10: "删除",
        t11: "正等待用户连接...",
        t12: "没有会话内容",
        t13: "当客户接入后，从左侧客户列表中选择客户开始会话",
        t14: "道友",
        t15: "点击右侧列表开始你的会话吧！",
        t16: "发送(s)",
        t17: "退出登录",
        t18: "加入了会话",
        t19: "重新加入了会话",
        t20: "道友莫要开玩笑，时代变了，您的上古浏览法器太落后了！",
        t21: "输入内容不能为空",
        t22: "对方已离线无法实时接收消息，但对方仍可以通过历史消息来查看此条消息",
        t23: "没有更多了",
        t24: "图片",
        t25: "表情",
        t26: "刷新成功",
        t27: "离线用户",
        t28: "搜索用户名",
        t29: "搜索",
        t30: "搜索结果为空",
        t31: "请不要在同一浏览器中访问用户端后，又访问客服端"
    },
    customerChat: {
        t1: "关闭",
        t2: "发送(s)",
        t3: "请输入会话内容",
        t4: "当前客服已离线",
        t5: "道友莫要开玩笑，时代变了，您的上古浏览法器太落后了！",
        t6: "您已关闭会话",
        t7: "发送内容不能为空",
        t8: "你撤回了一条消息",
        t9: "对方撤回了一条消息"
    },
    Comment: {
        t1: "请您留言",
        t2: "留言记录",
        t3: "提交",
        t4: "待回复",
        t5: "∧收起",
        t6: "查看详情",
        t7: "客服：",
        t8: "您好，很抱歉我们暂时无法为您提供服务，如需帮助，请留言，我们将尽快联系并解决您的问题",
        t9: "获取失败",
        t10: "提交成功",
        t11: "提交失败",
    },
    CommentReply: {
        t1: "已回复",
        t2: "取消",
        t3: "回复",
        t4: "客服：",
        t5: "提交",
        t6: "后续扩展内容",
        t7: "获取失败",
        t8: "回复成功",
        t9: "回复失败",
        t10: "留言回复",
        t11: "后续扩展内容",
        t12: "没有更多了"
    },
    HomeAiChat: {
        t1: "当前客服忙，您当前排在第",
        t2: "位",
        t3: "取消",
        t4: "请先取消排队",
        t5: "请选择人工客服：",
        t6: "初始化加载中，请耐心等待..."
    },
    MessageWindow: {
        t1: "加载更多",
        t2: "您已连接客服，开始咨询吧！（点击消息可撤回消息）",
        t3: "撤回",
        t4: "用户已连接，开始回复吧！（点击消息可撤回消息）",
        t5: "用户已离线，但你仍可对话，对方可通过历史消息查看",
        t6: "这是对方撤回的消息,但你仍可以点击查看",
        t7: "预览图片"
    },
    MyInput: {
        t1: "修改昵称",
        t2: "最大接待人数"
    },
    SendImage: {
        t1: "请选择图片文件!",
        t2: "上传图片不能超过10M!"
    },
    ServiceRightPage: {
        t1: "用户id：",
        t2: "用户名：",
        t3: "临时房间号：",
        t4: "临时服务id：",
        t5: "用户状态：",
        t6: "客户信息",
        t7: "快捷回复",
        t8: "轮子哥",
        t9: "用户ip：",
        t10: "用户地区：",
        t11: "用户设备：",
        t12: "退出编辑",
        t13: "编辑",
        t14: "删除",
        t15: "添加",
        t16: "添加快捷回复",
        t17: "修改快捷回复",
        t18: "删除快捷回复",
        t19: "你确定要删除吗？",
        t20: "请输入快捷语",
        t21: "添加成功",
        t22: "修改成功",
        t23: "删除成功",
    }
}
export default ZH